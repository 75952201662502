import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import PropTypes from 'prop-types'
import DataProtectionPoint from './DataProtectionPoint'
import { PointPropType } from '../propTypes'

const DataProtection = ({ header, points }) =>
  <SectionWrapper className='personal-data-protection'>
    <div className='personal-data-protection__header'>{header}</div>
    <ol>
      {points?.map((point, index) => <DataProtectionPoint key={index} {...point} />)}
    </ol>
  </SectionWrapper>

export default DataProtection

DataProtection.propTypes = {
  header: PropTypes.string.isRequired,
  points: PropTypes.arrayOf(PointPropType).isRequired,
}
