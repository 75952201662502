import React from 'react'
import Paragraph from '../Paragraph/Paragraph'
import PropTypes from 'prop-types'

const DataProtectionCompanyInfo = ({ body, phoneNumber, phoneNumberText, email }) => <>
  <Paragraph className='personal-data-protection-point__body' text={body} />
  <div className='personal-data-protection-point-link-container'>
    Tel.:<a href={phoneNumber}>{phoneNumberText}</a>
  </div>
  <div className='personal-data-protection-point-link-container'>
    E-Mail:<a href={email}>{email}</a>
  </div>
</>

export default DataProtectionCompanyInfo

DataProtectionCompanyInfo.propTypes = {
  body: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  phoneNumberText: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
}
