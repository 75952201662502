import React from 'react'
import DataProtection from '../components/DataProtection/DataProtection'
import Layout from '../components/Layout/Layout'
import { personalDataProtectionData } from '../components/consts'

const DatenschutzPage = () =>
  <Layout description='Datenschutz'>
    <DataProtection {...personalDataProtectionData} />
  </Layout>

export default DatenschutzPage
