import React from 'react'
import Paragraph from '../Paragraph/Paragraph'
import DataProtectionCompanyInfo from './DataProtectionCompanyInfo'
import DataProtectionLink from './DataProtectionLink'
import PropTypes from 'prop-types'
import { CompanyInfoPropType, DataProtectionLinkPropType } from '../propTypes'

const DataProtectionPoint = ({ header, body, companyInfoArray, links }) =>
  <li className='personal-data-protection-point'>
    <div className='personal-data-protection-point__header'>{header}</div>
    {body.map((body, index) => <React.Fragment key={index}>
        <Paragraph
          className='personal-data-protection-point__body'
          text={body}
        />
        {companyInfoArray?.[index] && <DataProtectionCompanyInfo {...companyInfoArray[index]} />}
      </React.Fragment>
    )}
    {links?.map((link, index) => <DataProtectionLink key={index} {...link} />)}
  </li>

export default DataProtectionPoint

DataProtectionPoint.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.arrayOf(PropTypes.string).isRequired,
  companyInfoArray: PropTypes.arrayOf(CompanyInfoPropType),
  links: PropTypes.arrayOf(DataProtectionLinkPropType)
}

DataProtectionPoint.defaultProps = {
  companyInfoArray: [],
  links: [],
}
