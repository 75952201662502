import React from 'react'
import PropTypes from 'prop-types'

const DataProtectionLink = ({ link, text }) => <>
  {text
    ? <div className='personal-data-protection-point-link-container'>
      <a href={link}>{link}</a> {text}
    </div>
    : <a href={link}>{link}</a>
  }
</>

export default DataProtectionLink

DataProtectionLink.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string
}

DataProtectionLink.defaultProps = {
  text: '',
}
